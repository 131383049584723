import * as React from 'react';
import { formatScore } from '../util.js';

export class AdminUserList extends React.Component {
	render() {
		const { isLive, authedUsers, isQuestionLive, currentAnswers, playerScores } = this.props;

		const newUserBox = (answer) => {
			let user = answer.user;
			let nickname = (user.nickname) ? user.nickname + ' (' + user.email + ')' : user.email;
			let score = (playerScores.hasOwnProperty(user.id)) ? formatScore(playerScores[user.id]) : 0;

			let statusClass = 'incorrect',
				onlineClass = (answer.online) ? ' online' : '',
				latestGuess = (answer.id) ? answer.answer : '',
				isLatestCorrect = (answer.id) ? answer.correct : false,
				isLatestHalfPoint = (answer.id) ? answer.half_point : false;

			//correct answer status
			if (isLatestCorrect) {
				if (!isLatestHalfPoint) {
					statusClass = 'correct';
				} else {
					statusClass = 'halfPoint';
				}
			}

			let answerRow = <div className={(isLive && !isQuestionLive && latestGuess !== '') ? 'answer present' : 'answer'}>{(!isLive && latestGuess === '') ? <i>N/A</i> : 'Answer:'} <span>{latestGuess}</span></div>;

			let showScoreButtons = (isLive && !isQuestionLive && latestGuess !== '') ? 'scoreButtons active' : 'scoreButtons';

			return (
				<li key={user.id} className={statusClass + onlineClass}>
					<div className="score">Score: {score}</div>
					<div className="email">{nickname}</div>
					{answerRow}
					
					<div className={showScoreButtons}>
						<div onClick={() => {this.props.markCorrect(answer.id, user.id)}} className="markCorrect">Correct</div>
						<div onClick={() => {this.props.markIncorrect(answer.id, user.id)}} className="markIncorrect">Incorrect</div>
						<div onClick={() => {this.props.markHalfPoint(answer.id, user.id)}} className="markHalfPoint">Half</div>
					</div>
				</li>
			);		
		}

		currentAnswers.sort((a, b) => (a.id < b.id ? -1 : 1));
		const userIDsWithAnswers = [];
		const onlineUserIDs = Object.keys(authedUsers).map((socketId) => {
			return authedUsers[socketId].id;
		});

		//user blocks with answers
		const userBoxes = currentAnswers.map((answer) => {
			userIDsWithAnswers.push(answer.user.id);
			answer.online = onlineUserIDs.includes(answer.user.id);
			return newUserBox(answer);
		});

		//user blocks without answers
		const noAnswerBoxes = Object.keys(authedUsers).reduce((result, socketId) => {
			if (!userIDsWithAnswers.includes(authedUsers[socketId].id)) {
				result.push(newUserBox({ user: authedUsers[socketId], online: true }))
			}

			return result;
		}, []);


		return (
			<React.Fragment>
				{ userBoxes }
				{ noAnswerBoxes }
			</React.Fragment>
		);
	}
}