import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import queryString from 'query-string';
import socketIOClient from 'socket.io-client';
import Cookies from 'universal-cookie';

import '../scss/GoogleAuth.scss';

const cookies = new Cookies();
const config = require('../config');

let socket;

export class GoogleAuth extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasCode: true,
			isAuthed: false,
			endpoint: config.endpoint + '/googleAuth', //last part is socket namespace
			message: 'Authenticating'
		}
	}

	componentDidMount() {
		if (cookies.get('triviaComputerJWT') !== undefined) {
			this.setState({ isAuthed: true });

		} else {
			const params = queryString.parse(this.props.location.search);
			
			if (params.code) {
				socket = socketIOClient(this.state.endpoint, {
					auth: { code: params.code }
				});

				socket.on('error', (err) => {
					this.setState({ message: "ERROR Authentication failed. Please try again. Redirecting..." });
					this.deleteCookie();
					console.log(err);

					setTimeout(() => {
						this.setState({ hasCode: false });
					}, 3000);				
				});

				socket.on('authError', (err) => {
					this.setState({ message: "Authentication failed. Please try again. Redirecting..." });
					this.deleteCookie();
					console.log(err);

					setTimeout(() => {
						this.setState({ hasCode: false });
					}, 3000);				
				});

				socket.on('authSuccess', data => {
					if (data.jwt) {
						cookies.set('triviaComputerJWT', data.jwt, {
							path: '/',
							sameSite: 'lax',
							expires: new Date(Date.now() + 315360000000)
						});

						//including localStorage option, will eventually replace cookie entirely
						window.localStorage.setItem('triviaComputerJWT', data.jwt);
					}

					this.setState({ isAuthed: true });
				});

			} else {
				this.setState({ hasCode: false });
			}
		}
	}

	deleteCookie() {
		if (cookies.get('triviaComputerJWT') !== undefined) {
			cookies.remove('triviaComputerJWT');	
		}
	}

	render() {
		if (!this.state.hasCode || this.state.isAuthed) {
			return <Redirect to="/" />
		}

		return(
			<Container className="min-vh-100" id="authRedirect">
				<Row className="min-vh-100 justify-content-center align-items-center">
					<Col className="authCol">
						<div className="loader"></div> {this.state.message}
					</Col>
				</Row>
			</Container>
		);
	}
}