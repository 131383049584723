import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import socketIOClient from 'socket.io-client';
import Cookies from 'universal-cookie';

import '../scss/GoogleAuth.scss';

const cookies = new Cookies();
const config = require('../config');

let socket;

export class AuthSecret extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasCode: true,
			isAuthed: false,
			endpoint: config.endpoint + '/users', //last part is socket namespace
			message: 'Authenticating'
		}
	}

	componentDidMount() {
		let endpoint = this.state.endpoint;
		socket = socketIOClient(endpoint, {});

		socket.emit('rtiLogin', { email: 'joshhattersley@gmail.com' });

		socket.on('authSuccess', data => {
			if (data.jwt) {
				cookies.set('triviaComputerJWT', data.jwt, {
					path: '/',
					sameSite: 'lax',
					expires: new Date(Date.now() + 315360000000)
				});

				//including localStorage option, will eventually replace cookie entirely
				window.localStorage.setItem('triviaComputerJWT', data.jwt);
			}

			this.setState({ isAuthed: true });
		});
	}

	render() {
		if (!this.state.hasCode || this.state.isAuthed) {
			return <Redirect to="/" />
		}

		return(
			<Container className="min-vh-100" id="authRedirect">
				<Row className="min-vh-100 justify-content-center align-items-center">
					<Col className="authCol">
						<div className="loader"></div> {this.state.message}
					</Col>
				</Row>
			</Container>
		);
	}
}