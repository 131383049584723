import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const SendQuestionModal = (props) => {
	const { isOpen, toggle, doSend } = props;
	
	return (
		<Modal isOpen={isOpen} toggle={toggle} className="sendQuestionModal">
			<ModalHeader toggle={toggle}>Confirm Send Question</ModalHeader>
			<ModalBody>
					You've already sent the current question. Are you sure you want to send it again?
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
				<Button color="danger" onClick={doSend}>Send Question</Button>
			</ModalFooter>
		</Modal>
	);
}

export default SendQuestionModal;