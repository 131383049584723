import * as React from 'react';
import { Col } from 'reactstrap';
import { formatScore } from '../util.js';

export class AdminStandings extends React.Component {
	render() {
		let scores = [],
			nickname = '',
			userNum = 1,
			isLive = this.props.isLive,
			activePlayers = this.props.activePlayers,
			playerScores = this.props.playerScores;

		for (let user of activePlayers) {
			if (user.nickname) {
				nickname = user.nickname;
			} else {
				nickname = 'Player '+userNum;
				userNum++;				
			}

			let score = 0;
			if (playerScores.hasOwnProperty(user.id)) {
				score = playerScores[user.id];
			}

			scores.push({ nickname: nickname, score: score });
		}

		scores.sort((a,b) => {
			return b.score - a.score;
		});

		const scoreType = (!isLive) ? 'Non-live Score' : 'Score';


		const currentScores = (!scores.length) ?
			<tr><td colSpan="2" className="notLive"><i>No current standings</i></td></tr> :
			scores.map((obj) =>
				<tr key={obj.nickname}><td>{obj.nickname}</td><td>{formatScore(obj.score)}</td></tr>
			);

		return (
			<Col xs="12" className="scoreDisplay">
				<div>
					<table>
						<thead><tr><th>Nickname</th><th className="score">{scoreType}</th></tr></thead>
						<tbody>{currentScores}</tbody>
					</table>
				</div>
			</Col>
		);
	}
}