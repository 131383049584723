import * as React from 'react';
import { Col } from 'reactstrap';

const ReactMarkdown = require('react-markdown/with-html');

export class ComputerLiveAnnouncement extends React.Component {
	render() {
		if (!this.props.isLive && this.props.announcement) {
			return (
				<Col xs="12" className="announcement">
					<div>
						<ReactMarkdown source={this.props.announcement} escapeHtml={false} />
					</div>
				</Col>				
			);
		}

		return null;
	}
}