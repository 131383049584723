import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const RevealAnswerModal = (props) => {
	const { isOpen, toggle, doReveal } = props;
	
	return (
		<Modal isOpen={isOpen} toggle={toggle} className="revealAnswerModal">
			<ModalHeader toggle={toggle}>Confirm Reveal Answer</ModalHeader>
			<ModalBody>
					You haven't sent the current question yet. Are you sure you want to reveal the answer?
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
				<Button color="danger" onClick={doReveal}>Reveal Answer</Button>
			</ModalFooter>
		</Modal>
	);
}

export default RevealAnswerModal;