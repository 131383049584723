import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, Progress } from 'reactstrap';
import { Layout } from '../Layout';
import { AdminUserList } from './AdminUserList';
import { AdminStandings } from './AdminStandings';
import socketIOClient from 'socket.io-client';
import Cookies from 'universal-cookie';

import '../scss/GoogleAuth.scss';
import '../scss/AdminInterface.scss';

import RevealAnswerModal from './RevealAnswerModal.js';
import SendQuestionModal from './SendQuestionModal.js';

const ReactMarkdown = require('react-markdown');
const cookies = new Cookies();
const config = require('../config');

let socket;

export class AdminInterface extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			endpoint: config.endpoint + '/admin', //last part is socket.io namespace
			doRedirect: false,
			isAdmin: false,
			isSuperAdmin: false,
			
			totalPlayers: 0,
			authedUser: false,
			authedUsers: {},
			currentAnswers: [],
			playerScores: {},
			activePlayers: [],

			//admin and live event stuff
			isLive: false,
			announcement: '',
			questionSets: [],
			questionSetId: '',
			allSetQuestions: [],
			questionId: false,
			question: {},
			progress: 0,
			isQuestionLive: false,
			showScores: false,
			revealingAnswer: false,
			hasShownQuestion: false,
			isRevealModalOpen: false,
			isSendModalOpen: false
		}

		this.handleQuestionSetSelect = this.handleQuestionSetSelect.bind(this);
		this.handleQuestionSelect = this.handleQuestionSelect.bind(this);
		this.handleAnnouncementChange = this.handleAnnouncementChange.bind(this);
		this.handlePublishAnnouncement = this.handlePublishAnnouncement.bind(this);
		this.handleClearAnnouncement = this.handleClearAnnouncement.bind(this);
		this.toggleShowScores = this.toggleShowScores.bind(this);
		this.handleSendQuestion = this.handleSendQuestion.bind(this);
		this.handleRevealAnswer = this.handleRevealAnswer.bind(this);
		this.toggleRevealModal = this.toggleRevealModal.bind(this);
		this.toggleSendModal = this.toggleSendModal.bind(this);
		this.handleConfirmedReveal = this.handleConfirmedReveal.bind(this);
		this.handleConfirmedSend = this.handleConfirmedSend.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
	}
	
	componentDidMount() {
		let socketOptions = {},
			endpoint,
			token = '';

		//testing cookie
		if (config.enableTestUsers && config.testJwtAdmin) {
			cookies.set('triviaComputerJWT', config.testJwtAdmin);
		}
		
		//localStorage method
		if (window.localStorage.getItem('triviaComputerJWT') !== null) {
			token = window.localStorage.getItem('triviaComputerJWT');

		//cookie method (to be deprecated)
		} else if (cookies.get('triviaComputerJWT') !== undefined) {
			token = cookies.get('triviaComputerJWT');
		}

		//immediate auth fail, redirects
		if (token === '') {
			this.setState({ doRedirect: true });
			
		//check authentication
		} else {
			socketOptions.auth = {
				adminToken: token
			}
			
			endpoint = this.state.endpoint;
			socket = socketIOClient(endpoint, socketOptions);
			
			socket.on('isAdmin', data => {
				this.initializeAdmin(socket, data);
			});
			
			//auth fails
			socket.on('error', err => { this.setState({ doRedirect: true }); });
			socket.on('isNotAdmin', () => { this.setState({ doRedirect: true }); });
		}
	}
	
	initializeAdmin(socket, data) {
		let question = data.liveEvent.question;
			question.answers = data.liveEventHidden.originalAnswer;
			question.trivia_answers = data.liveEventHidden.triviaAnswers;
		let	isSuperAdmin = (data.super_admin > 0);

		this.setState({
			isAdmin: true,
			isSuperAdmin: isSuperAdmin,
			isLive: data.liveEvent.isLive,
			authedUser: data.email,
			authedUsers: data.users,
			totalPlayers: Object.keys(data.users).length,
			announcement: data.liveEvent.announcement,
			questionSets: data.liveEventHidden.allQuestionSets,
			allSetQuestions: data.liveEventHidden.allSetQuestions,
			questionSetId: data.liveEventHidden.questionSetId,
			questionId:  data.liveEvent.questionId,
			question: question,
			isQuestionLive: data.liveEvent.activeQuestion,
			revealingAnswer: data.liveEvent.revealAnswer,
			showScores: data.liveEvent.showScores
		});

		//load selected question answer data
		if (!!data.liveEvent.questionId) {
			socket.emit('selectQuestion', { questionId: data.liveEvent.questionId, solo: true });
		}
		
		socket.on('updateUsers', data => this.setState({ totalPlayers: data.users }));
		socket.on('updatePlayers', data => this.setState({ authedUsers: data.users }));
		socket.on('updateActivePlayers', data => this.setState({ activePlayers: data.users }))

		socket.on('loadPlayerScores', data => this.setState({ playerScores: data.scores }));
		socket.on('updatePlayerScore', data => this.updatePlayerScore(data));

		socket.on('loadCurrentAnswers', data => this.setState({ currentAnswers: data }));
		socket.on('updateCurrentAnswer', data => this.updateCurrentAnswer(data));
		
		socket.on('allQuestionSets', sets => this.setState({ questionSets: sets }));
		socket.on('allSetQuestions', questions => this.setState({ allSetQuestions: questions, questionId: false, question: {} }));
		socket.on('question', question => this.handleQuestionReceived(question));
		socket.on('updateTimer', data => this.setState({ progress: data }));
		socket.on('questionOver', () => this.setState({
			isQuestionLive: false, progress: { countdown: 100, remaining: 0 }
		}));

		socket.on('updateAdminState', data => this.updateAdminStateReceive(data.stateKey, data.val, data.socketId));
		socket.on('resetLiveState', () => this.resetLiveState());

		socket.on('forceRefresh', () => { window.location.reload(); })
	}

	updatePlayerScore(data) {
		let playerScores = {...this.state.playerScores};
		playerScores[data.userId] = data.score;
		this.setState({ playerScores: playerScores });
	}

	//update single current answer in array
	updateCurrentAnswer(data) {
		let updated = false;
		for (let i = 0; i < this.state.currentAnswers.length; i++) {
			if (parseInt(this.state.currentAnswers[i].id) === parseInt(data.id)) {
				let allAnswers = [...this.state.currentAnswers];
				let answer = {...allAnswers[i]};

				answer.correct = data.correct;
				answer.half_point = data.half_point;

				if (data.answer) {
					answer.answer = data.answer;
				}

				allAnswers[i] = answer;
				updated = true;

				this.setState({ currentAnswers: allAnswers });
				break;
			}
		}

		if (!updated) {
			let allAnswers = [...this.state.currentAnswers];
			allAnswers.push(data); //new answer object
			this.setState({ currentAnswers: allAnswers });
		}
	}

	computedActivePlayers() { //all active players, including authed and initial active list
		let activePlayers = [];
		let playerIds = this.state.activePlayers.map(a => parseInt(a.id));

		//move active players into variable
		for (let i = 0; i < this.state.activePlayers.length; i++) {
			activePlayers.push(this.state.activePlayers[i]);
		}

		for (let socketId in this.state.authedUsers) {
			//pull directly from authedUsers info
			if (!playerIds.includes(parseInt(this.state.authedUsers[socketId].id))) {
				activePlayers.push(this.state.authedUsers[socketId]);

			//make sure to update info in activePlayers
			} else {
				for (let i = 0; i < activePlayers.length; i++) {
					if (parseInt(activePlayers[i].id) === parseInt(this.state.authedUsers[socketId].id)) {
						activePlayers[i] = this.state.authedUsers[socketId];
					}
				}
			}
		}

		return activePlayers;
	}

	handleAnnouncementChange(event) {
		this.setState({ announcement: event.target.value });
	}

	handlePublishAnnouncement() {
		socket.emit('updateAnnouncement', { announcement: this.state.announcement });
		this.updateAdminStatePush('announcement', this.state.announcement);
	}

	handleClearAnnouncement() {
		let doClearAnnouncement = window.confirm('Are you sure you want to clear the announcement?');

		if (doClearAnnouncement) {
			this.setState({ announcement: '' });
			socket.emit('updateAnnouncement', { announcement: '' });
			this.updateAdminStatePush('announcement', '');
		}
	}

	handleQuestionSetSelect(event) {
		this.setState({ questionSetId: event.target.value, hasShownQuestion: false });
		socket.emit('selectQuestionSet', { questionSetId: event.target.value });
		this.updateAdminStatePush('questionSetId', event.target.value);
	}

	handleQuestionSelect(event) {
		this.setState({ questionId: event.target.value, hasShownQuestion: false });
		socket.emit('selectQuestion', { questionId: event.target.value });
		this.updateAdminStatePush('questionId', event.target.value);
	}

	handleQuestionReceived(question) {
		this.setState({ question: question });
	}

	handleSendQuestion() {
		if (this.state.hasShownQuestion) {
			this.setState({ isSendModalOpen: true });
		} else {
			this.setState({ isQuestionLive: true, hasShownQuestion: true });
			socket.emit('sendQuestion');
			this.updateAdminStatePush('isQuestionLive', true);
		}
	}

	handleConfirmedSend() {
		this.setState({ isQuestionLive: true, hasShownQuestion: true, isSendModalOpen: false });
		socket.emit('sendQuestion');
		this.updateAdminStatePush('isQuestionLive', true);		
	}

	handleRevealAnswer(reveal) {
		//confirm answer reveal if we haven't shown question yet
		if (reveal && !this.state.hasShownQuestion) {
			this.setState({ isRevealModalOpen: true });

		} else {
			this.setState({ revealingAnswer: reveal });
			socket.emit('revealAnswer', reveal);
			this.updateAdminStatePush('revealingAnswer', reveal);
		}
	}

	handleConfirmedReveal() {
		this.setState({
			revealingAnswer: true,
			isRevealModalOpen: false
		});

		socket.emit('revealAnswer', true);
		this.updateAdminStatePush('revealingAnswer', true);
	}

	toggleRevealModal() {
		this.setState({ isRevealModalOpen: !this.state.isRevealModalOpen });
	}

	toggleSendModal() {
		this.setState({ isSendModalOpen: !this.state.isSendModalOpen });
	}

	handleToggleTrivia(enable) {
		if (enable) {
			this.setState({ isLive: true, progress: 0, hasShownQuestion: false });
			socket.emit('goLive');

			this.updateAdminStatePush('isLive', true);
			this.updateAdminStatePush('progress', 0);
			this.updateAdminStatePush('hasShownQuestion', false);

		} else {
			let doDisable = window.confirm('Are you sure you want to end this live trivia event?');
			if (doDisable) {
				this.setState({ isLive: false });
				socket.emit('endLive');

				this.updateAdminStatePush('isLive', false);
			}
		}
	}

	toggleShowScores() {
		let doShowScores;
		if (!this.state.showScores) {
			doShowScores = window.confirm('Are you sure you want to show the scoreboard to all players?');
		}

		if (this.state.showScores || doShowScores) {
			if (!this.state.showScores) {
				socket.emit('showScores');
			} else {
				socket.emit('hideScores');
			}

			this.setState({ showScores: !this.state.showScores });
			this.updateAdminStatePush('showScores', !this.state.showScores);		
		}
	}

	refreshNicknames() {
		let doRefresh = window.confirm('Are you sure you want to refresh nicknames from the database?');
		if (doRefresh) {
			socket.emit('refreshNicknames');
		}
	}

	refreshQuestionSets() {
		let doRefresh = window.confirm('Are you sure you want to refresh question sets from the database?');
		if (doRefresh) {
			socket.emit('refreshQuestionSets');
		}
	}

	shortQuestion(question, len = 30) {
		let ell = (question.length > len) ? '...' : '';
		return question.substr(0,len).trim() + ell;
	}

	//handlers for user list correction functions
	//uses currently set questionId on backend, hence not passed here
	markCorrect(answerId, userId) {
		socket.emit('markCorrect', { answerId: answerId, userId: userId });
	}

	markIncorrect(answerId, userId) {
		socket.emit('markIncorrect', { answerId: answerId, userId: userId });
	}

	markHalfPoint(answerId, userId) {
		socket.emit('markCorrect', { answerId: answerId, userId: userId, half_point: true });
	}

	//push updated admin interface state to other connected admins
	updateAdminStatePush(stateKey, val) {
		socket.emit('updateAdminState', { stateKey: stateKey, val: val, socketId: socket.id });
	}

	updateAdminStateReceive(stateKey, val, socketId) {
		if (socketId !== socket.id) {
			this.setState({ [stateKey]: val });
		}	
	}

	resetLiveState() {
		this.setState({
			showScores: false,
			revealingAnswer: false,
		});
	}

	buildQuestionSetSelect() {
		let optgroups = {};
		this.state.questionSets.forEach((set) => {
			let d = new Date(set.date).toLocaleDateString('en-us');
			
			if (optgroups.hasOwnProperty(d)) {
				optgroups[d].push(set);
			} else {
				optgroups[d] = [set];
			}
		});

		let ret = [];
		for (let group in optgroups) {
			let opts = optgroups[group].map((set) => 
				<option key={set.id} value={set.id}>{set.name}</option>
			);

			ret.push(<optgroup key={group} label={group}>{opts}</optgroup>);
		}

		return ret;
	}

	archiveTriviaAnswers() {
		let doArchive = window.confirm('Are you sure you want to archive all trivia answers and clear user nicknames and scores? THIS ACTION CANNOT BE UNDONE.');

		if (doArchive) {
			socket.emit('archiveTriviaAnswers');
		}		
	}

	handleLogout() {
		if (cookies.get('triviaComputerJWT') !== undefined) {
			cookies.remove('triviaComputerJWT');	
		}

		if (window.localStorage.getItem('triviaComputerJWT') !== null) {
			window.localStorage.removeItem('triviaComputerJWT');
		}
		
		window.location.reload(); //easiest way to clear settings
	}
	
	render() {
		//failed auth
		if (this.state.doRedirect) {
			return <Redirect to="/" />
		}
		
		//checking auth
		if (!this.state.isAdmin) {
			return (
				<Container className="min-vh-100" id="authRedirect">
					<Row className="min-vh-100 justify-content-center align-items-center">
						<Col className="authCol">
							<div className="loader"></div> Authenticating
						</Col>
					</Row>
				</Container>
			);
			
		//successful auth
		} else {
			const {
				totalPlayers,
				authedUser,
				authedUsers,
				progress,
				currentAnswers,
				playerScores
			} = this.state;

			//standings
			const activePlayers = this.computedActivePlayers();

			//trivia set list
			const questionSetSelectItems = this.buildQuestionSetSelect();

			//trivia question list
			const allSetQuestions = this.state.allSetQuestions.map((question) =>
				<option key={question.id} value={question.id}>{question.order + '. ' + this.shortQuestion(question.question)}</option>
			);

			//enable/disable live trivia buttons
			const liveTriviaButtons = (!this.state.isLive) ? <Button color="primary" onClick={() => { this.handleToggleTrivia(true); }} disabled={!this.state.isSuperAdmin}>Enable</Button> : <Button color="danger" onClick={() => { this.handleToggleTrivia(false); }} disabled={this.state.isQuestionLive || this.state.revealingAnswer || this.state.showScores || !this.state.isSuperAdmin}>Disable</Button>;

			const handleRevealAnswerButtons = (!this.state.revealingAnswer) ? <Button color="warning" onClick={() => {this.handleRevealAnswer(true); }} disabled={!this.state.isLive || !this.state.questionId || this.state.isQuestionLive || this.state.showScores}>{ this.state.questionId ? 'Reveal Answer' : 'No question selected' }</Button> : <Button color="warning" onClick={() => { this.handleRevealAnswer(false); }} disabled={!this.state.isLive}>{ this.state.questionId ? 'Hide Answer' : 'No question selected' }</Button>

			//send question button text
			const sendQuestionText = (this.state.isQuestionLive) ? 'Question '+this.state.question.order+' is live!' : (this.state.questionId) ? 'Send Question '+this.state.question.order : 'No question selected';

			//show scores to audience
			let showScores;
			if (!this.state.showScores) {
				showScores = <Button disabled={!this.state.isLive || this.state.isQuestionLive || this.state.revealingAnswer} onClick={this.toggleShowScores} color="primary">Show Scores</Button>;
			} else {
				showScores = <Button disabled={!this.state.isLive} onClick={this.toggleShowScores} color="primary">Hide Scores</Button>;
			}

			/***
				MODULAR DISPLAY COMPONENTS
			***/
			const elAnnouncement =
				<Row><Col>
				<div className="innerCol announcement">
					<div className="title">Announcement</div>

					<div className="area">
						<label>Set Announcement (Markdown)</label>
						<textarea value={this.state.announcement} onChange={this.handleAnnouncementChange} />

						<div className="d-flex justify-content-end">
							<Button color="secondary" onClick={this.handleClearAnnouncement}>Clear</Button>
							<Button color="secondary" onClick={this.handlePublishAnnouncement}>Publish</Button>
						</div>
					</div>
				</div>
				</Col></Row>

			const elControls =
				<div className="innerCol controls">
					<div className="title">Controls</div>

					<Row>
						<Col md="4">
							<div className="area">
								<label>Send Question</label>
								<Button color="success" onClick={this.handleSendQuestion} disabled={!this.state.isLive || !this.state.questionId || this.state.isQuestionLive || this.state.revealingAnswer || this.state.showScores}>{ sendQuestionText }</Button>
							</div>
						</Col>

						<Col md="4">
							<div className="area">
								<label>Question Progress { progress.remaining ? '('+progress.remaining+')' : ''}</label>
								<div className="progressContainer">
									<Progress color="info" value={ progress.countdown ? progress.countdown : 0 } />
								</div>										
							</div>
						</Col>

						<Col md="4">
							<div className="area">
								<label>Toggle Live Trivia</label>
								{liveTriviaButtons}
							</div>
						</Col>
					</Row>

					<Row>
						<Col md="4">
							<div className="area">
								<label>Reveal Answer</label>
								{handleRevealAnswerButtons}
							</div>
						</Col>

						<Col md="4">
							<div className="area">
								<label>Show Scores</label>
								{showScores}
							</div>													
						</Col>

						<Col md="4" className={this.state.isSuperAdmin ? '' : 'hidden'}>
							<div className="area refresh">
								<label>Database Actions</label>
								<Button color="secondary" onClick={this.refreshNicknames} disabled={this.state.isQuestionLive}>Nicks</Button>
								<Button color="secondary" onClick={this.refreshQuestionSets} disabled={this.state.isQuestionLive}>Qs</Button>
								<Button color="secondary" onClick={this.archiveTriviaAnswers} disabled={this.state.isLive}>Arch.</Button>
							</div>
						</Col>
					</Row>
				</div>

			const elTrivia =
				<div className="innerCol trivia">
					<div className="title">Trivia Selection</div>

					<div className="area">
						<label>Current Question Set</label>
						<select value={this.state.questionSetId} onChange={this.handleQuestionSetSelect} disabled={this.state.isQuestionLive || this.state.revealingAnswer}>
							<option value="">Select a Question Set</option>
							{questionSetSelectItems}
						</select>
					</div>

					<div className="area">
						<label>Current Question</label>
						<select disabled={!this.state.allSetQuestions.length || this.state.isQuestionLive || this.state.revealingAnswer} value={this.state.questionId} onChange={this.handleQuestionSelect}>
							<option value="">Select a Question</option>
							{allSetQuestions}
						</select>
					</div>

					<div className="area">
						<div className="theQuestion">
							<ReactMarkdown source={ this.state.question.question } />
						</div>
						<div className="theAnswer">
							{ (this.state.question.answers !== '' && this.state.question.answers !== undefined) ? <ReactMarkdown source={ this.state.question.answers } /> : <p>No question selected</p> }

							<div className="theNotes">
								{ (this.state.question.answers !== '' && this.state.question.answers !== undefined && this.state.question.notes ) ? <ReactMarkdown source={ this.state.question.notes } /> : '' }
							</div>
						</div>
					</div>
				</div>


			const elPlayers =
				<div className="innerCol players">
					<div className="title">Scoring <span className="liveOnline">{Object.keys(authedUsers).length} online</span></div>
					<ul>
						<AdminUserList isLive={this.state.isLive} authedUsers={authedUsers} questionId={this.state.questionId} markCorrect={this.markCorrect} markIncorrect={this.markIncorrect} markHalfPoint={this.markHalfPoint} isQuestionLive={this.state.isQuestionLive} currentAnswers={currentAnswers} playerScores={playerScores} />
					</ul>
				</div>

			const elStandings =
				<div className="innerCol standings">
					<div className="title">Standings</div>
					<AdminStandings isLive={this.state.isLive} activePlayers={activePlayers} playerScores={playerScores} />
				</div>

			
			/***
				RENDERED LAYOUT
			***/
			return (
				<Layout users={ totalPlayers } authedUser={ authedUser } handleLogout={this.handleLogout}>
					<SendQuestionModal isOpen={this.state.isSendModalOpen} toggle={this.toggleSendModal} doSend={this.handleConfirmedSend} />

					<RevealAnswerModal isOpen={this.state.isRevealModalOpen} toggle={this.toggleRevealModal} doReveal={this.handleConfirmedReveal} />

					<Container id="adminInterface">
						<Row>
							<Col lg="4" className="adminLeft">
								{ elTrivia }
								<div className="d-lg-block d-md-none d-sm-none d-none">
									{ elStandings }
								</div>
							</Col>

							<Col lg="8" className="adminRight">
								{ elControls }
								{ elPlayers }
								<div className="d-block d-lg-none d-xl-none">
									{ elStandings }
								</div>
								{ elAnnouncement }
							</Col>
						</Row>
					</Container>
				</Layout>
			);
		}
	}
}