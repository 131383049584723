import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const LogoutModal = (props) => {
	const { isOpen, cancelAuth, doAuth, userEmail } = props;
	
	return (
		<Modal isOpen={isOpen} toggle={() => {}} className="logoutModal">
			<ModalHeader toggle={cancelAuth}>Duplicate Login</ModalHeader>
			<ModalBody>
					<p>You are already logged in on another browser or device using the email address <b>{userEmail}</b>, and only one session per email is allowed.</p>
					<p>If you would like to log in here, or are not aware of any other active sessions, click <b>Confirm Login</b>. Otherwise, click <b>Cancel</b>.</p>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={cancelAuth}>Cancel</Button>{' '}
				<Button color="danger" onClick={doAuth}>Confirm Login</Button>
			</ModalFooter>
		</Modal>
	);
}

export default LogoutModal;