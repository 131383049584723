import * as React from 'react';
import { Container, Row, Col, Tooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './scss/Layout.scss';

const config = require('./config');

export class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tooltip1Open: false,
			tooltip2Open: false,
			tooltip3Open: false,
			donations: [false,false,false]
		}
		this.toggle = this.toggle.bind(this);
	}

	toggle(target) {
		this.setState({
			[target]: !this.state[target]
		});
	}

	componentDidMount() {
		//special cors policy in place to allow this on pillarist config
		/*fetch(config.donationsUrl, {
			headers : {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		}).then(response => {
			response.json().then(donations => {
				if (donations.current) {
					this.setState({ donations: donations.current });
				}
			});
		});*/
	}

	render () {
		const loginWarning = (this.props.isLive && !this.props.authedUser) ? <Row><Col id="loginWarning"><div>Enter your RTI email address in the field below then click submit.<div className="emailEntry"><input name="email" type="email" placeholder="E.g., jhattersley@rti.org" value={this.props.rtiEmail} onChange={this.props.handleEmailChange} /></div><div className="submitArea"><button disabled={this.props.submittingEmail} onClick={this.props.handleEmailSubmit}>{ !this.props.submittingEmail ? 'submit' : 'saving email...'}</button></div></div></Col></Row> : '';

		const adminLogin = (this.props.authedUser && this.props.isAdmin) ? <li className="adminLogin"><a href="/admin">Switch to Admin Panel</a></li> : '';

		const { donations } = this.state;

		return (
			<div>
				<div id="header">
					<Container>
						<Row>
							<Col md="8">
								<a href="/" className="title">trivia.computer</a>		
								<div className={'users ' + (this.props.users > 0 ? 'active' : '')}>{ this.props.users } { this.props.users > 1 ? 'users': 'user' }</div>
							</Col>
							<Col md="4">
								<ul className="navList d-flex justify-content-md-end">
								{
									(this.props.googleAuthUrl && !this.props.authedUser)
										? <li><a className="googleAuth rti" href="/">Trivia with CETA</a></li>
										: <li></li>
								}
								{
									(this.props.authedUser)
										? <><li className="userName">{this.props.authedUser}</li><li className="logout"><button onClick={this.props.handleLogout} title="Log Out"><FontAwesomeIcon icon="sign-out-alt" /></button></li></>
										: <li></li>									
								}
								</ul>
							</Col>
						</Row>

						{loginWarning}
					</Container>
				</div>
				
				{ this.props.children }
				
				<div id="footer">
					<Container>
						<Row>
							<Col xs="12" className="content">
								<ul>
									<li>© {new Date().getFullYear()} Trivia Computer</li>
									{ adminLogin }
								</ul>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
}
