import * as React from 'react';
import { Col } from 'reactstrap';
import { ComputerLiveAnnouncement } from './ComputerLiveAnnouncement';
import { formatScore } from '../util.js';

export class ComputerLiveDisplay extends React.Component {
	render() {
		if (this.props.isLive) {
			if (this.props.showScores) {
				const currentScores = this.props.currentScores.map((obj) =>
					<tr key={obj.nickname}><td>{obj.nickname}</td><td>{formatScore(obj.score)}</td></tr>
				);

				return (
					<Col xs="12" className="scoreDisplay">
						<div>
							<table>
								<thead><tr><th>Nickname</th><th>Score</th></tr></thead>
								<tbody>{currentScores}</tbody>
							</table>
						</div>
					</Col>
				);
			} else {
				return (
					<ComputerLiveAnnouncement isLive={false} announcement={this.props.announcement} />
				)
			}
		}

		return null;
	}
}